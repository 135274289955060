export const PLAYER_ONE = 1;
export const PLAYER_TWO = -1;

export class TicTacToe {

    public currPlayer: number;

    private board: number[];

    constructor() {
        this.reset();
    }

    public reset() {
        this.board = [];
        for (let i = 0; i < 9; i++) {
            this.board.push(0);
        }
        this.currPlayer = 1;
    }

    public move(move: number) {
        this.board[move] = this.currPlayer;
        this.currPlayer = this.currPlayer == PLAYER_ONE ? PLAYER_TWO : PLAYER_ONE;
    }

    public getMove(move: number) {
        return this.board[move];
    }

    public getMoves() {
        const moves = [];
        for (let i = 0; i < 9; i++) {
            if (this.board[i] == 0) {
                moves.push(i);
            }
        }
        return moves;
    }

    public key() {
        return this.board.join('');
    }

    // return [WINNER, [moves]]
    public isDone(): any {
        let winning_moves = [];
        for (let y = 0; y < 3; y++) {
            winning_moves = [];
            let sum = 0;
            for (let x = 0; x < 3; x++) {
                winning_moves.push(y * 3 + x);
                sum += this.getMove(y * 3 + x);
            }

            if (Math.abs(sum) == 3) {
                return [sum / 3, winning_moves];
            }
        }

        for (let x = 0; x < 3; x++) {
            winning_moves = [];
            let sum = 0;
            for (let y = 0; y < 3; y++) {
                winning_moves.push(y * 3 + x);
                sum += this.getMove(y * 3 + x);
            }

            if (Math.abs(sum) == 3) {
                return [sum / 3, winning_moves];
            }
        }

        let sum = 0;
        winning_moves = [];
        for (let i of [0, 4, 8]) {
            winning_moves.push(i);
            sum += this.getMove(i);
        }
        if (Math.abs(sum) == 3) {
            return [sum / 3, winning_moves];
        }

        sum = 0;
        winning_moves = [];
        for (let i of [2, 4, 6]) {
            winning_moves.push(i);
            sum += this.getMove(i);
        }
        if (Math.abs(sum) == 3) {
            return [sum / 3, winning_moves];
        }

        return [0, []]
    }
}