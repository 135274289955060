import React, { useState } from 'react';

import { Checkbox, Button, Typography } from '@material-ui/core';
import Header from '../../components/header';
import { PLAYER_ONE, PLAYER_TWO, TicTacToe} from '../../components/tic-tac-toe/tic_tac_toe';
import PLAYER_ONE_DATA from '../../components/tic-tac-toe/playerOneData_500k.json';
import PLAYER_TWO_DATA from '../../components/tic-tac-toe/playerTwoData_500k.json';

import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faQuestion } from '@fortawesome/free-solid-svg-icons';
import GithubIcon from '../../components/github';

const game = new TicTacToe();

function getClasses(player: number, move: number, winningMoves: number[], winner: number) {
    let spotClass = 'spot ';
    
    if (winningMoves.includes(move)) {
        spotClass += 'winning_spot';
    } else if (player == PLAYER_ONE) {
        spotClass += 'player_one';
    } else if (player == PLAYER_TWO) {
        spotClass += 'player_two';
    } else {
        spotClass += 'empty';
    }

    if (winner != 0) {
        spotClass += ' done';
    }
    
    return spotClass;
}

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

function Spot(move: number, winningMoves: number[], winner: number, values: any, forceUpdate: any, showValues: boolean, showBestMove: boolean) {
    let predictedValue = 0
    if (winner == 0 && game.getMoves().length > 0) {
        predictedValue = values[move];
    }
    let player = game.getMove(move);
    let isGameOver = winner != 0 || game.getMoves().length == 0;

    let color = '';
    if (predictedValue == 1 && showBestMove) {
        color = 'rgba(0,255,0,.8)'
    }

    return (
        // <Tooltip title={predictedValue} key={move}>
            <div key={move} onClick={() => {
                if (player != 0 || winner != 0) return;
                game.move(move);
                forceUpdate();
            }} style={{background:color}} className={getClasses(game.getMove(move), move, winningMoves, winner)}>
                { showValues ? <div className='value-text'>
                    {
                        player == 0 && !isNaN(predictedValue) ?
                        predictedValue.toFixed(3) :
                        player == 0 ?
                        'Unknown' : ''}
                </div> : null}
                {
                    player == PLAYER_ONE ?
                    <FontAwesomeIcon className='icon' icon={faTimes} /> :
                    player == PLAYER_TWO ? 
                    <FontAwesomeIcon className='icon' icon={faCircle} /> :
                    <div className={!isGameOver ? 'icon-player' : 'hidden'}><FontAwesomeIcon className='icon' icon={game.currPlayer == PLAYER_ONE ? faTimes : faCircle} /></div>
                }
            </div>
        // </Tooltip>
    );
}

const headerDetails = {
    title: 'Tic Tac Toe with Q Learning',
    img: '/tic-tac-toe.png',
    description: 'Play with an AI For Tic Tac Toe based on Q Learning. You can also learn how to make it yourself!',
}

function QLearningPage () {
    const forceUpdate = useForceUpdate();
    const [showValues, setShowValues] = useState(false);
    const [showBestMove, setShowBestMove] = useState(false);

    const [winner, winningMoves] = game.isDone();

    let updatedValues = {}
    if (winner == 0 && game.getMoves().length > 0) {
        let dat = game.currPlayer == PLAYER_ONE ? PLAYER_ONE_DATA[game.key()] : PLAYER_TWO_DATA[game.key()];
        let v: any = Object.values(dat);
        let mx = Math.max(...v);
        let mn = Math.min(...v);
        let diff = mx - mn;
        Object.keys(dat).forEach(x => {
            updatedValues[x] = (dat[x] - mn) / diff;

            if (diff == 0) {
                updatedValues[x] = 1;
            }
        })
    }

    let subText = '';
    if (winner == PLAYER_ONE) {
        subText = "Player One Wins!"
    } else if (winner == PLAYER_TWO) {
        subText = "Player Two Wins!"
    } else if (game.getMoves().length == 0) {
        subText = "It's a draw"
    } else if (game.currPlayer == PLAYER_ONE) {
        subText = "Player One's Move"
    } else if (game.currPlayer == PLAYER_TWO) {
        subText = "Player Two's Move"
    }

    return (
        <Header details={headerDetails}>
            <div style={{textAlign:"center", marginTop:'15px'}}>
                <Typography variant='h3'>
                    Tic-Tac-Toe
                </Typography>
                <div>
                    Find the code on <GithubIcon height={'16px'} link={"https://github.com/spenc53/machine-learning/tree/main/TicTacToe_QLearning"}/>
                </div>

                <div style={{marginTop:'10px'}}>
                    <Typography variant='h5'>
                        {subText}
                    </Typography>
                    {/* Board */}
                    <div className='board'>
                        <div className='row'>
                        {
                            [0,1,2].map((i: number) => Spot(i, winningMoves, winner, updatedValues, forceUpdate, showValues, showBestMove))
                        }
                        </div>
                        <div className='row'>
                        {
                            [3,4,5].map((i: number) => Spot(i, winningMoves, winner, updatedValues, forceUpdate, showValues, showBestMove))
                        } 
                        </div>
                        <div className='row'>
                        {
                            [6,7,8].map((i: number) => Spot(i, winningMoves, winner, updatedValues, forceUpdate, showValues, showBestMove))
                        } 
                        </div>
                    </div>
                </div>

                {/* after board */}
                <div style={{marginTop: '20px'}}>
                    <div style={{display:'inline-flex'}}>
                        <div style={{textAlign:'center'}}>
                            Show Move Values
                            <div>
                                <Checkbox checked={showValues} onChange={() => {
                                    setShowValues(!showValues)
                                }}/>
                            </div>
                        </div>

                        <div style={{textAlign:'center', marginLeft:'20px'}}>
                            Highlight Best Move
                            <div>
                                <Checkbox checked={showBestMove} onChange={() => {
                                    setShowBestMove(!showBestMove)
                                }}/>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style={{marginTop:'10px'}}>
                    <div style={{display:'inline-flex'}}>
                        <Button
                            style={{marginRight:'10px'}}
                            variant='contained'
                            color='secondary'
                            disabled={winner != 0 || game.getMoves().length == 0}
                            onClick={() => {
                                let move = -1;
                                let bestMove = -99;
                                Object.keys(updatedValues).forEach((m) => {
                                    if (move == -1) {
                                        move = parseInt(m);
                                        bestMove = updatedValues[m];
                                    }

                                    if (bestMove < updatedValues[m]) {
                                        move = parseInt(m);
                                        bestMove = updatedValues[m];
                                    }
                                });

                                if (move == -1) {
                                    move = game.getMoves()[0];
                                }

                                game.move(move);
                                forceUpdate();
                            }}
                        >Let AI Move</Button>
                        <Button variant='contained' color='secondary' onClick={() => {game.reset(); forceUpdate();}}>Reset Game</Button>
                    </div>
                </div>

                {/* <div>
                    <Button>Let AI Move</Button>
                </div> */}
                
                <div className='description'>
                    <Typography variant='h4'>
                        Q-Learning: How it Works
                    </Typography>
                    <p>
                        Q-Learning in simplest terms is that it makes a look up table to find the value of any given (state, action) pair. In Tic-Tac-Toe, the input is the board and a spot that can move. In the above demo, when you enable seeing the values, this is simply looking up the value of that move given the game's current state. I normalized the values to be between 0 and 1 to make it easier to understand.
                    </p>
                    <p>
                        The natural follow up question after knowing that Q-Learning makes a look up table of values is: how does it do that? The answer lies with the Bellman equation (link to Wiki). Let's review what this equation is. I'll do my best to break it down as the first time I learned about this I had to spend a decent bit of time reading multiple articles.
                    </p>

                    <Typography variant='body1'>
                        Bellman Equation:
                    </Typography>
                    <div>
                        <code>Q(s,a) = (1-alpha) * Q(s,a) + alpha * (R(s,a) + lambda * max(Q(s', a')))</code>
                    </div>

                    <p>
                        Let's break it down into each individual component.
                    </p>

                    <p>
                        <code>Q(s,a)</code> {'->'} This is the look up table. Aka what is the value of the (state, action) pair.
                    </p>
                    <p>
                        <code>(1-alpha) * Q(s,a)</code> {'->'} This looks confusing but, it's just saying how much of our previous knowledge we should use to update the Q-Table with.
                    </p>
                    <p>
                        <code>R(s,a)</code> {'->'} This is the reward for making action "a" at some state "s". In Tic Tac Toe, all rewards are 0, unless it leads to a win / loss state. In which case, it returns 1 / -1.
                    </p>
                    <p>
                        <code>max(Q(s', a'))</code> {'->'} We want to know the maximum future reward after performing action "a" at state "s". "s'" and "a'" refer to the next state and next set of avaiable moves.
                    </p>
                    <p>
                        <code>lambda * max(Q(s', a'))</code> {'->'} We want to add in a discounted future reward. This helps back prop future rewards (such as winning).
                    </p>

                    <p>
                        When this equation is performed many times, eventually it will reach a steady state. This means that the q-table no longer is changing and the reward for each state has converged to its true value.
                    </p>

                    <Typography variant='h4'>
                        A Simple Game and Example
                    </Typography>

                    <p>
                        Let's examine a simple game where the only purpose of the game is to move to the Right. The game has a starting state of 1000. Each move to the right move the 1 to the right. So, 1 move right at from the beginning state is 0100. We can easily find all the states of this game. They are: 1000, 0100, 0010, and 0001. The reward funciton is simple. If the state is 0001, then the reward is 1, otherwise it is 0.
                    </p>

                    <p>The Q-table initially looks like the below.</p>
                    <table style={{width:'100%'}}>
                        <tr>
                            <th>Move / State</th>
                            <th>1000</th>
                            <th>0100</th>
                            <th>0010</th>
                            <th>0001</th>
                        </tr>
                        <tr>
                            <th>Right</th>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                        </tr>
                    </table>

                    <p>
                        Let's assume we want to run an update for the state 0010 and the action move right. Let's find the values we need. We will use alpha = 0.05 and lambda = .9
                    </p>
                    
                    <p>
                        Q(state, action) = Q(0010, Right) = 0
                    </p>

                    <p>
                        R(state, action) = Reward(0010, Right) = Value of state 0001 = 1
                    </p>

                    <p>
                        Max(Q(s', a')) = Max value of all moves at the state after 0010 and action right. This means we need to find the max value of state 0001. Max(Q(0001, a)) = 0.
                    </p>

                    <p>
                        We have found all the values now, we need to put it all together.<br/>
                        New Q(s,a) = (1-alpha) * Q(0010, Right) + alpha * [R(0010, Right) + lambda * Max(Q(0001, a))]<br/>
                        New Q(s,a) = (1 - .05) * 0 + .05 * [1 + .9 * 0]<br/>
                        New Q(s,a) = 0.05
                    </p>

                    <table style={{width:'100%'}}>
                        <tr>
                            <th>Move / State</th>
                            <th>1000</th>
                            <th>0100</th>
                            <th>0010</th>
                            <th>0001</th>
                        </tr>
                        <tr>
                            <th>Right</th>
                            <td>0</td>
                            <td>0</td>
                            <td>.05</td>
                            <td>0</td>
                        </tr>
                    </table>

                    <p>
                        You can now do the same thing for the other move states. Here is an updated table after finding the value for 0100.
                    </p>

                    <table style={{width:'100%'}}>
                        <tr>
                            <th>Move / State</th>
                            <th>1000</th>
                            <th>0100</th>
                            <th>0010</th>
                            <th>0001</th>
                        </tr>
                        <tr>
                            <th>Right</th>
                            <td>0</td>
                            <td>.045</td>
                            <td>.05</td>
                            <td>0</td>
                        </tr>
                    </table>

                    <p>
                        As you keep doing this, the values will eventually converge to some values. These values should be the optimal playing values. If you use them while playing the game, your AI should perform pretty well.
                    </p>

                    <Typography variant='h4'>
                        My Implementation
                    </Typography>
                    <p>
                        You can find my implementation on github here: <GithubIcon link={"https://github.com/spenc53/machine-learning/tree/main/TicTacToe_QLearning"}></GithubIcon>
                    </p>

                    <p>
                        I implemented the bellman equation as written above. However, since this is a two player game, I did have to some thinking. Normally, the reward function works after making a move but, since this is a two player game, the opponent must make a move unless the game has ended. To do this, I trained 2 "Q-Learning agents" at the same time. When training player 1, I had player 2 make a move right after player 1 played to get the reward function. To train player 2, I had player 1 play right after player 2 made a move. I trained for 500k games, this took about 2-3 minutes on my mac. I highly suggest looking at my code if you want the nitty gritty details of how it worked.
                    </p>
                </div>
            </div>
        </Header>
    )
}

export default QLearningPage;